import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "applications/move",
    loadChildren: () =>
      import(
        "./modules/applications/move-application/move-application.routes"
      ).then((m) => m.routes),
  },
  {
    path: "approvals/:id",
    loadChildren: () =>
      import("./modules/approval/approval.module").then(
        (m) => m.ApprovalModule,
      ),
  },
  {
    path: "customer/agent",
    loadChildren: () =>
      import("./modules/customer-agent/customer-agent.module").then(
        (m) => m.CustomerAgentModule,
      ),
  },
  {
    path: "static/ok",
    loadChildren: () =>
      import("./modules/static/ok/ok.module").then((m) => m.OkModule),
  },
  {
    path: "**",
    loadComponent: () =>
      import("@kalmarenergi/features").then((m) => m.NotFoundComponent),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
